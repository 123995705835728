
@font-face {
  font-family: 'Alphabet';
  src: local("Alphabet"), url(./fonts/Alfabet.otf) format('opentype');
}
@font-face {
  font-family: 'Alphabet-light';
  src: local("Alphabet"), url(./fonts/Alfabet.otf) format('opentype');
}

@font-face {
  font-family: 'Alphabet-semibold';
  src: local("Alphabet-semibold"), url(./fonts/Alfabet-semibold.otf) format('opentype');
}


.App {
  text-align: center;
  overflow: hidden; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo{
  position: absolute;
  top: 64px;
  left: 80px;
  height: 30px;
}

.menu {    
  position: absolute;
  top: 64px;
  right: 80px;
  transition: transform 0.5s ease-in-out;
  cursor:pointer;
  display: inline-block;
}

.menu-icons {
  position: absolute;
  top: 110px;
  right: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10,
}


@media (max-width: 768px) {
  .logo {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;
  }
  .menu {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .menu-icons {
    position: absolute;
    top: 60px;
    right: 15px;
  }
}

