.comingSoonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    width: 271px;
    max-width: 100%;
    padding-bottom: 72px;
    font-family: 'Alphabet';
    font-size: 18px;
    font-weight: 800px;
}

.comingSoonButton {
    height: 64px;
    background-color: #1f2124;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    width: 100%;
    border: 0.5px solid #3e3b3b;
}

.enterEmail { 
    max-width:80%;
    display: flex;
    flex-direction: row;
    height:100%;
    border-radius: 32px;
    font-size: 20px;
    border: none;
    color: white;
    align-content:flex-start;
    align-items: center;
}
.emailInput {
    align-content: 'center';
    border-radius: 32px;
    font-size: 20px;
    border: none;
    background-color: #1f2124;
    color: white;
    flex-grow: 1;
    align-items:center;
}
.submitButton {
    width:20px;
    height:20px;
}

.theoText {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.shorts {
        max-height: 70%;
        max-width: 90%;
        object-fit:cover;
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
}

@media (max-width: 768px) {
    .theoText {
      position: absolute;
      top: 190px;
      left: 50%;
      transform: translateX(-50%);
    }
    .comingSoonContainer {
        padding-bottom: 130px;
    }
    .emailInput { 
        width:150px;
    }
    .shorts {
        max-height: 85%;
        max-width: 100%;
    }
}

  