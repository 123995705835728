
@font-face {
  font-family: 'Alphabet';
  src: local("Alphabet"), url(./fonts/Alfabet.otf) format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; 
  width: 100%; /* Fixes any potential overflow issues */
  height: 100%; /* Fixes any potential overflow issues */
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  overflow-x: hidden; 
  width: 100%; /* Fixes any potential overflow issues */
  height: 100%; /* Fixes any potential overflow issues */
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.socialsContainer {
  animation: slideInFromRight 0.5s ease-out forwards;
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slideOutFromRight {
  animation: slideOutToRight 0.5s ease-out forwards;
}

input {
  border: 1px solid #ccc; /* Default border */
}

input:focus {
  outline: none; /* Remove the default focus outline */
  border: none; /* No border on focus */
}

@keyframes slideFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.thanksForSubscribingIn {
  animation: slideFromBottom 0.5s ease-out forwards;
}

.thanksForSubscribingOut {
  animation: slideOutToBottom 0.5s ease-out forwards;
}

@keyframes slideOutToBottom {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%) ;
    opacity: 0;
  }
}

.slideOut {
  animation: slideOutToBottom 0.5s ease-out forwards;
}